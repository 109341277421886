import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import './Error.scss';

function Error() {
  return (
    <Container className="text-center error-box">
      <h1>This link has expired.</h1>
      <a href="https://www.maschaefer.net">
        <Button variant="dark" size="sm" className="button-back">◄ go back</Button>
      </a>
    </Container>
  );
}

export default Error;
