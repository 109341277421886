import { Switch, Route } from "wouter";
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Downloader from "./Downloader";
import Error from "./Error";
import Login from "./Login";
import Create from "./Create";
import logo from "./img/icon.png";

import './App.scss';

function App() {
  return (
    <div className="App">
      <Navbar bg="dark" variant="dark" className="Navbar">
        <Container>
          <Navbar.Brand>
            <img
              alt="logo"
              src={logo}
              width="24"
              height="24"
              className="d-inline-block align-top Navbar-logo"
            />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Switch>
        <Route path="/" component={Login}></Route>
        <Route path="/create" component={Create}></Route>
        <Route path="/:file" component={Downloader}></Route>
        <Route default component={Error}></Route>
      </Switch>
      <Navbar bg="light" fixed="bottom" className="Footer">
        <span className="footer-text">
          © {new Date().getFullYear()} Michael Schaefer
        </span>
      </Navbar>
    </div>
  );
}

export default App;
