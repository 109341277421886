import { useState } from "react";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Error from "./Error";
import QRCode from 'react-qr-code';
import file_icon from "./img/file.png";

import "./Downloader.scss";

const decode = (b64) => {
  const n = b64.length % 4;
  const padded = b64 + "=".repeat(n > 0 ? 4 - n : n);
  return atob(padded.replace(/\-/g, "+").replace(/\_/g, "/"));
};

const parseContent = (b64) => {
  try {
    var jsonContent = JSON.parse(decode(b64));
    if ("u" in jsonContent && "n" in jsonContent && "s" in jsonContent) {
      if (jsonContent["s"]) {
        const file_name = (jsonContent["u"] === "") ? jsonContent["n"] : jsonContent["u"];
        jsonContent["dl"] = new URL(
          file_name,
          "https://0296d23e86ffe2ff7597c57aeea6da8457aeea6da84.s3.us-west-2.amazonaws.com/"
        ).href;
      } else {
        jsonContent["dl"] = jsonContent["u"];
      }
      return jsonContent;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

function Downloader(props) {
  const [qrShow, setQrShow] = useState(false);
  const c = parseContent(props.params["file"]);

  if (c !== false) {
    return (
      <div>
        <Container className="Downloader text-center">
          <img
            alt="File"
            src={file_icon}
            width="128"
            height="128"
            className="file-icon"
          />
          <p className="h3">"{c["n"]}"</p>
          <p className="shared-by">Michael Schaefer shared this item.</p>
          <p>
            <a
              href={c["dl"]}
              className="download-button btn btn-primary"
              download
            >
              Download
            </a>
          </p>
          <p>
            <Button
              variant="outline-secondary"
              className="qr-button"
              onClick={() => setQrShow(true)}
            >
              Show QR Code
            </Button>
          </p>
        </Container>
        <Modal
          size="sm"
          show={qrShow}
          onHide={() => setQrShow(false)}
          aria-labelledby="qr-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="qr-modal">QR Code</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="file-qr">
              <QRCode level="M" value={c["dl"]} />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  } else {
    return <Error />;
  }
}

export default Downloader;
