import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import "./Create.scss";

class Create extends React.Component {
  constructor(props) {
    super(props);
    this.state = { name: "", url: "", s3: false, result: "" };

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleURLChange = this.handleURLChange.bind(this);
    this.handleS3Change = this.handleS3Change.bind(this);
    this.updateResult = this.updateResult.bind(this);
  }

  encode(string) {
    return(btoa(string)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+/, ""))
  }

  handleNameChange(event) {
    this.setState({ name: event.target.value }, () => {
      this.updateResult();
    });
  }

  handleURLChange(event) {
    this.setState({ url: event.target.value }, () => {
      this.updateResult();
    });
  }

  handleS3Change(event) {
    this.setState({ s3: event.target.checked }, () => {
      this.updateResult();
    });
  }

  updateResult() {
    this.setState({
      result: this.encode(
        JSON.stringify({
          n: this.state["name"],
          u: this.state["url"],
          s: this.state["s3"],
        })
      ),
    });
  }

  render() {
    return (
      <Container>
        <Row>
          <Col className="create-box">
            <h3>Create Download Link</h3>
            <Form>
              <Form.Group className="mb-3" controlId="formName">
                <Form.Label>Filename:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  value={this.state.name}
                  onChange={this.handleNameChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formURL">
                <Form.Label>URL:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="URL"
                  value={this.state.url}
                  onChange={this.handleURLChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formS3">
                <Form.Label>Default S3 Bucket:</Form.Label>
                <Form.Check
                  type="checkbox"
                  value={this.state.s3}
                  onChange={this.handleS3Change}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formResult">
                <Form.Label>Link:</Form.Label>
                <Form.Control
                  type="text"
                  value={window.location.origin + "/" + this.state.result}
                  disabled
                />
              </Form.Group>
            </Form>
            <a href="https://app.rebrandly.com/links" target="_blank"><small>Create Short Link</small></a>

          </Col>
          <Col></Col>
        </Row>
      </Container>
    );
  }
}

export default Create;
