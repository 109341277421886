import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';

import './Login.scss';

function Login() {
  return (
    <Container className="text-center login-box">
      <p className="h2">Sign in</p>
      <InputGroup>
        <FormControl
          type="password"
          placeholder="Password"
          aria-label="Login"
        />
        <Button variant="dark" disabled>go</Button>
      </InputGroup>

    </Container>
  );
}

export default Login;
